/* eslint-disable no-case-declarations */
import { GET_MENUS, ADD_MENU, REMOVE_MENU } from '../actions/menu';

const initialState = { list: [] };

const reducer = (state, action) => {
    switch (action.type) {
        case GET_MENUS:
            return {
                list: action.data,
            };
        case ADD_MENU:
            return { list: action.data };
        case REMOVE_MENU:
            return { list: action.data };
        default:
            return state;
    }
};

export default [reducer, initialState];
