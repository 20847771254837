const fetch = require('utils/fetchRequest');

export const GET_MENUS = 'GET_MENUS';
export const ADD_MENU = 'ADD_MENU';
export const REMOVE_MENU = 'REMOVE_MENU';

export const getOneMenu = async (id) => {
    try {
        const response = await fetch.get('menus/' + id);
        return response.data;
    } catch (err) {
        return { error: err };
    }
};

export const getMenus = async (dispatch, query) => {
    try {
        const response = await fetch.get('menus?' + query);
        dispatch({
            type: GET_MENUS,
            data: response.data,
        });
        return response.data;
    } catch (err) {
        return { error: err };
    }
};

export const getMenuList = async (query) => {
    try {
        const response = await fetch.get('menus' + query);
        return response.data;
    } catch (err) {
        return { error: err };
    }
};

export const addMenu = async (dispatch, datas) => {
    try {
        const response = await fetch.post('menus', datas);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const editMenu = async (datas, id) => {
    try {
        const response = await fetch.put('menus/' + id, datas);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const deleteMenu = async (dispatch, id) => {
    try {
        const response = await fetch.destroy('menus/' + id);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};
