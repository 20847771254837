const fetch = require('utils/fetchRequest');

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const login = async (dispatch, data) => {
    try {
        const response = await fetch.post('users/login', data);
        if (response.code === 200) {
            dispatch({
                type: LOGIN_SUCCESS,
                data: response.data,
            });
        }
        return response;
    } catch (err) {
        dispatch({
            type: LOGIN_ERROR,
        });
    }
};

export const logout = async (dispatch) => {
    dispatch({
        type: LOGOUT,
        datas: {},
    });
};
