import combineReducers from 'react-combine-reducers';
import auth from './auth';
import menu from './menu';
import news from './news';

const [reducer, initialState] = combineReducers({
    menu,
    news,
    auth,
});

export { reducer, initialState };
