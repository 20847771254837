import React, { useEffect } from 'react';
// import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../constants/theme';
import { ContextState } from '/context';
import AdminLayout from '/layouts/AdminLayout';
import '../styles/globals.css';

import ProgressBar from '@badrap/bar-of-progress';

const progress = new ProgressBar({
    size: 3,
    color: '#1e93f0',
    className: 'bar-of-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

export default function MyApp(props) {
    const { Component, pageProps } = props;

    const Layout = Component.Layout || AdminLayout;

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <React.Fragment>
            <Head>
                <title>БУЛГАН АЙМАГ ТЭШИГ СУМ</title>
                <link rel="shortcut icon" href="/favicon.png" />
                <meta charSet="utf-8" />
                <meta property="og:title" content="teshig.bu.gov.mn" key="title" />
                <meta property="og:title" content="БУЛГАН АЙМАГ ТЭШИГ СУМ" key="ogtitle" />
                <meta property="og:site_name" content="teshig.bu.gov.mn" key="ogsitename" />
                <meta
                    property="og:description"
                    content="Булган аймаг тэшиг сум албан ёсны вэб сайт"
                    key="ogdesc"
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                    rel="stylesheet"
                ></link>
                {/* <meta property="og:image" content={previewImage} key="ogimage" /> */}
                {/* <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                /> */}
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYBLACKITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYBOLD.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYHEAVYITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYLIGHTITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYMEDIUM.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYREGULAR.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYSEMIBOLDITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYTHINITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
                <link
                    rel="preload"
                    href="/fonts/SFPRODISPLAY/SFPRODISPLAYULTRALIGHTITALIC.OTF"
                    as="font"
                    crossOrigin=""
                />
            </Head>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ContextState>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ContextState>
            </ThemeProvider>
        </React.Fragment>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};
