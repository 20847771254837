/* eslint-disable no-case-declarations */
const GET_CATS = 'GET_NEWS_CATS';

const initialState = {
    cats: [
        {
            id: 1,
            name: 'Боловсрол',
        },
        {
            id: 2,
            name: 'Эрүүл мэнд',
        },
        {
            id: 3,
            name: 'Соёл урлаг',
        },
        {
            id: 4,
            name: 'Байгаль орчин',
        },
        {
            id: 5,
            name: 'Сургалт',
        },
        {
            id: 6,
            name: 'Хууль эрхзүй',
        },
        {
            id: 7,
            name: 'Ярилцлага',
        },
    ],
};

const reducer = (state, action) => {
    switch (action.type) {
        case GET_CATS:
            return {
                list: action.data,
            };
        default:
            return state;
    }
};

export default [reducer, initialState];
