const typography = {
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto, sans-serif',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    h1: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
        color: 'black',
    },
    h2: {
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
        letterSpacing: '-0.24px',
    },
    h3: {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        lineHeight: 1.4,
    },
    h4: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
    },
    h5: {
        fontSize: 13,
        fontWeight: 'normal',
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
    },
    h6: { // header menu
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: 0,
        fontFamily: 'Roboto, sans-serif',
    },
    overline: {
        fontWeight: 500,
    },
    body1: {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body2: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    subtitle1: {
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.1px',
        color: 'black',
    },
    subtitle2: {
        fontSize: 15,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
    },
    button: {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
        textTransform: 'none',
    },
};

export default typography;
