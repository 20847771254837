import {
    Image as ImageIcon,
    Menu as MenuIcon,
    GitPullRequest as GitPullRequestIcon,
    Sun as SunIcon,
    List as ListIcon,
    Columns as ColumnsIcon,
} from 'react-feather';

const adminMenu = [
    {
        href: '/admin/news',
        icon: ColumnsIcon,
        title: 'Мэдээ мэдээлэл',
    },
    {
        href: '/admin/projects',
        icon: ImageIcon,
        title: 'Төсөл хөтөлбөр',
    },
    {
        href: '/admin/docs',
        icon: ImageIcon,
        title: 'Захирамж, Тогтоол',
    },
    {
        href: '/admin/menus/0',
        icon: MenuIcon,
        title: 'Цэс удирдах',
    },
    {
        href: '/admin/posts/0',
        icon: MenuIcon,
        title: 'Цэсний контент',
    },
    {
        href: '/admin/sliders',
        icon: ImageIcon,
        title: 'Баннер зураг',
    },
    {
        href: '/admin/jobs',
        icon: SunIcon,
        title: 'Ажлын зар',
    },
    {
        href: '/admin/poll',
        icon: ListIcon,
        title: 'Санал асуулга',
    },
    {
        href: '/admin/programs',
        icon: ListIcon,
        title: 'Нүүрний цэснүүд',
    },
];

export default adminMenu;
